import styled from "@emotion/styled"
import { Backdrop, Collapse } from "@mui/material"
import { FetchMenusQuery } from "apollo"
import Link from "next/link"
import { usePathname } from "next/navigation"
import { Mobile } from "ui"
import { extractMenuProperties, getNonUmbracoUrl, mainMenuClick } from "utils"
import NavLink from "./NavLink"

const StyledMenu = styled(Collapse)`
  min-height: -webkit-fill-available;
  position: fixed;

  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 100000;
  background-color: #fff;
  width: 100%;
  border-end-start-radius: 20px;
  border-end-end-radius: 20px;
`

const StyledMenuHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: 64px;
  align-items: center;
`

export const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0px;
`
export const StyledList = styled.nav`
  display: flex;
  flex-direction: column;
  padding: 0px;
  padding-bottom: 22px;
  height: 100dvh;
  overflow: scroll;
  ${(props) => props.theme.breakpoints.up("md")} {
    max-width: 825px;
    overflow: hidden;
    justify-content: space-between;
    flex-direction: row;
    height: auto;
    padding: 0px;
    gap: 25px;
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    max-width: 1055px;
  }
`
export const StyledLinkHeb = styled(Link)<{ selected: boolean }>`
  text-decoration: none;
  font-style: normal;
  font-weight: ${(props) => (props.selected ? "500" : "400")};
  font-size: 16px;
  line-height: 22px;

  color: ${(props) => props.theme.colors.black};
`

export const StyledLinkEn = styled(Link)<{ selected: boolean }>`
  position: relative;
  top: 1px;
  text-decoration: none;

  font-style: normal;
  font-weight: ${(props) => (props.selected ? "900" : "400")};
  font-size: 14px;
  line-height: 20px;

  color: ${(props) => props.theme.colors.black};
`
export const StyledLink = styled(Link)<{ selected?: boolean }>`
  display: flex;
`
export const StyledImageAndName = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 30px;
  padding: 0px 16px;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-bottom: 0px;
  }
`

type Props = {
  // eslint-disable-next-line no-unused-vars
  setOpen: (open: boolean) => void
  open: boolean
  menuItems: FetchMenusQuery
}

const TierraSideMenu = ({ menuItems, open, setOpen }: Props) => {
  const pathname = usePathname()
  return (
    <Mobile>
      <StyledMenu in={open}>
        <StyledMenuHeader></StyledMenuHeader>

        <StyledList role="navigation">
          {menuItems?.menu_mobile?.children?.map((item: any, index: number) => {
            const { title, url } = extractMenuProperties(item)
            const decodedPathname =
              decodeURI(pathname) === "/" ? decodeURI(pathname) : decodeURI(pathname) + "/"

            return (
              <NavLink
                onClick={() => {
                  setOpen(false)
                  item?.children?.length === 0 && mainMenuClick(title, "")
                }}
                key={index.toString()}
                href={getNonUmbracoUrl(url)}
                selected={decodedPathname === getNonUmbracoUrl(url)}
                title={title}
              />
            )
          })}
        </StyledList>
      </StyledMenu>
      <Backdrop open={open} sx={{ color: "#fff", zIndex: -1 }} onClick={() => setOpen(false)} />
    </Mobile>
  )
}

export default TierraSideMenu
