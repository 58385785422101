import styled from "@emotion/styled"
import { FetchMenusQuery } from "apollo"
import Image from "next/image"
import Link from "next/link"
import { usePathname } from "next/navigation"
import React, { useEffect, useState } from "react"
import { Container, Desktop, Mobile } from "ui"
import ImageWithAlt from "ui/ImageWithAlt"
import { StyledLink, StyledList } from "ui/layout/SideMenu"
import { extractMenuProperties, mainMenuClick } from "utils"
import NavLink from "./NavLink"
import TierraSideMenu from "./TierraSideMenu"
import { getNonUmbracoUrl } from "./getNonUmbracoUrl"

export const StyledLinkHeb = styled(Link)<{ selected: boolean }>`
  text-decoration: none;
  font-style: normal;
  font-weight: ${(props) => (props.selected ? "500" : "400")};
  font-size: 16px;
  padding-right: 8px;
  border-right: 1px solid black;
  line-height: 22px;

  color: ${(props) => props.theme.colors.black};
`

export const StyledButton = styled.button`
  background-color: rgba(162, 201, 0, 1);
  border: none;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 0px;
`

export const StyledLinkEn = styled(Link)<{ selected: boolean }>`
  position: relative;
  top: 1px;
  text-decoration: none;
  padding-left: 8px;
  border-left: 1px solid black;
  font-style: normal;
  font-weight: ${(props) => (props.selected ? "900" : "400")};
  font-size: 16px;
  line-height: 20px;

  color: ${(props) => props.theme.colors.black};
`
const StyledRelative = styled.header`
  position: relative;
  z-index: 9999;
`
const StyledHeader = styled.div<{ isOpen: boolean }>`
  position: fixed;
  z-index: 100001;
  top: 0px;
  left: 0px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  height: 64px;
  align-items: center;
  box-sizing: border-box;
  padding: 0px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    height: 98px;
    padding: 0;
  }
`

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  align-items: center;
  padding: 0;
`

function TierraHeader({
  locale,
  data,
  logo,
}: {
  logo: any
  locale: string
  data: FetchMenusQuery
}) {
  const [open, setOpen] = React.useState<boolean>(false)

  const pathname = usePathname()
  const [submenuLink, setSubMenuLink] = useState("")
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }

    // Cleanup to prevent possible side effects when the component unmounts
    return () => {
      document.body.style.overflow = "auto"
    }
  }, [open])

  const menuDesktop = data?.menu_desktop?.children

  return (
    <StyledRelative role="banner">
      <StyledHeader isOpen={open}>
        <Container>
          <StyledContainer>
            <Desktop>
              <StyledLink
                href={`/${locale === "he" ? "he" : ""}`}
                onClick={() => {
                  mainMenuClick("Logo", "")
                  setSubMenuLink("")
                }}
              >
                <ImageWithAlt
                  style={{ objectFit: "contain", objectPosition: "right" }}
                  image={logo}
                  width={170}
                  height={64}
                  priority
                />
              </StyledLink>
            </Desktop>
            <Mobile>
              <StyledLink
                href={`/${locale === "he" ? "he" : ""}`}
                onClick={() => {
                  mainMenuClick("Logo", "")
                  setSubMenuLink("")
                }}
              >
                <ImageWithAlt
                  style={{ objectFit: "contain", objectPosition: "center" }}
                  image={logo}
                  width={106}
                  height={40}
                  priority
                />
              </StyledLink>
            </Mobile>
            <Mobile>
              <StyledButton
                aria-expanded={open ? true : false}
                aria-label={open ? "סגירת תפריט" : "פתיחת תפריט"}
                onClick={() => {
                  setOpen((prev) => !prev)
                  mainMenuClick(`${open ? "hamburger close" : "hamburger open"}`, "")
                }}
              >
                <Image
                  src={open ? "/icons/close.svg" : "/icons/burger.svg"}
                  alt="burger"
                  width={24}
                  height={24}
                />
              </StyledButton>
            </Mobile>
            <Desktop>
              <StyledList role="navigation">
                {/* Need to figure out how to have types in graphql */}
                {menuDesktop?.map((item: any, index: number) => {
                  const decodedPathname =
                    decodeURI(pathname) === "/" ? decodeURI(pathname) : decodeURI(pathname) + "/"

                  const { title, url, name } = item
                    ? extractMenuProperties(item)
                    : { title: "", url: "/", name: "" }

                  return (
                    <NavLink
                      key={index.toString()}
                      href={getNonUmbracoUrl(url)}
                      selected={
                        decodedPathname === getNonUmbracoUrl(url) ||
                        submenuLink === title ||
                        (name?.length > 0 && decodeURI(pathname).includes(name?.toLowerCase()))
                      }
                      title={title}
                      onClick={() => {
                        setSubMenuLink(title)
                        mainMenuClick(title, "")
                      }}
                    />
                  )
                })}
              </StyledList>
            </Desktop>
          </StyledContainer>
        </Container>
      </StyledHeader>

      <TierraSideMenu menuItems={data} open={open} setOpen={setOpen} />
    </StyledRelative>
  )
}

export default TierraHeader
